// SponsorsBar.js
import React, { useEffect, useState } from 'react';
import sponsorData from '../data/sponsors.json';
import '../styles/sponsorsbar.scss';

const SponsorsBar = () => {
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    setSponsors(sponsorData);
  }, []);

  return (
    <div className="sponsors-bar" style={{ marginTop: '120px' }}>
      <div className="scrolling-track">
        {[...sponsors, ...sponsors].map((sponsor, index) => (
          sponsor.url ? (
            <a
              key={index}
              href={sponsor.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`/sponsors/${sponsor.filename}`}
                alt={sponsor.alt}
                className="sponsor-logo"
              />
            </a>
          ) : (
            <img
              key={index}
              src={`/sponsors/${sponsor.filename}`}
              alt={sponsor.alt}
              className="sponsor-logo"
            />
          )
        ))}
      </div>
    </div>
  );
};

export default SponsorsBar;
