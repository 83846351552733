// src/pages/Membersarea.js
import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { auth } from "../firebase";
import HeaderTop from '../components/headertop';
import SponsorsBar from './sponsorsbar';
import '../styles/contactus.scss'; // reuse same styling!
import { Helmet } from 'react-helmet-async';  

const AUTO_LOGOUT_TIME = 10 * 60 * 1000; // 10 minutes

const Membersarea = () => {
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let logoutTimer;

    const resetLogoutTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        signOut(auth).then(() => {
          navigate("/");
        });
      }, AUTO_LOGOUT_TIME);
    };

    if (user) {
      resetLogoutTimer();
      window.addEventListener("mousemove", resetLogoutTimer);
      window.addEventListener("keydown", resetLogoutTimer);
    }

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetLogoutTimer);
      window.removeEventListener("keydown", resetLogoutTimer);
    };
  }, [user, navigate]);

  if (user) {
    navigate("/dashboard");
    return null;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (err) {
      setError("Invalid email or password");
    }
  };

  return (
    <div className="contact-page"> {/* reuse same layout class */}
      <HeaderTop />
      <SponsorsBar />
      <div className="contact-container">
        <h1>Members Area Login</h1>
        <p style={{ textAlign: "center", color: "white", marginBottom: "1.5rem" }}>
          Here we allow our exclusive members to view updates, photos and videos.
        </p>
        {error && <p className="error" style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Membersarea;
