// src/pages/Gallery.js
import React, { useState } from 'react';
import HeaderTop from '../components/headertop';
import SponsorsBar from './sponsorsbar';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../styles/gallery.scss';
import { Helmet } from 'react-helmet-async';

<Helmet>
  <title>Gallery | Malaysian Warriors FC</title>
  <meta name="description" content="Explore the Malaysian Warriors photo gallery." />
</Helmet>


const Gallery = () => {
  const images = [
    '/gallery/BJ01.png',
    '/gallery/BJ02.png',
    '/gallery/25AW2017.jpg',
    '/gallery/25AW2017Main.jpg',
    '/gallery/Want to know more.jpeg',
    '/gallery/Training Pic.JPG'
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <div className="gallery-page">
      <HeaderTop />
      <SponsorsBar />
      <div className="gallery-container">
        {images.map((src, index) => (
          <div key={index} className="gallery-item" onClick={() => { setPhotoIndex(index); setIsOpen(true); }}>
            <img src={src} alt={`Gallery Image ${index + 1}`} />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default Gallery;
