// src/pages/ContactUs.js
import React, { useState, useEffect } from 'react';
import HeaderTop from '../components/headertop';
import SponsorsBar from './sponsorsbar'; // Capitalized to match file
import ReCAPTCHA from 'react-google-recaptcha';
import '../styles/contactus.scss';
import { useForm, ValidationError } from "@formspree/react";

const ContactUs = () => {
  const [captchaValue, setCaptchaValue] = useState(null);
  const [state, handleSubmit] = useForm("xblgzynn"); // ✅ Use your real ID here

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const onSubmit = (e) => {
    if (!captchaValue) {
      alert('Please verify the CAPTCHA before submitting.');
      return;
    }
    handleSubmit(e);
  };

  useEffect(() => {
    if (state.succeeded) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [state.succeeded]);

  return (
    <div className="contact-page">
      <HeaderTop />
      <SponsorsBar />
      <div className="contact-container">
        <h1>Contact Us or we can contact you</h1>

        {state.succeeded ? (
          <p className="success-message">
            ✅ Thanks! Your message has been sent.
          </p>
        ) : (
          <form onSubmit={onSubmit}>
            <input id="name" type="text" name="name" placeholder="Your Name" required />
            <ValidationError prefix="Name" field="name" errors={state.errors} />

            <input id="email" type="email" name="email" placeholder="Your Email" required />
            <ValidationError prefix="Email" field="email" errors={state.errors} />

            <input id="phone" type="tel" name="phone" placeholder="Contact Phone" required />
            <ValidationError prefix="Phone" field="phone" errors={state.errors} />

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
            />

            <button type="submit" disabled={state.submitting}>
              Send
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
