// src/App.js
import './App.css';
import { Routes, Route } from 'react-router-dom';
import MainPage from './pages/mainpage';
import Members from './pages/members';
import ContactUs from './pages/contact';
import Gallery from './pages/gallery';

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/members" element={<Members />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/gallery" element={<Gallery />} />
    </Routes>
  );
}

export default App;
