// src/components/HeaderTop.js
import React from 'react';
import './headertop.scss';
import { Link } from 'react-router-dom';

const HeaderTop = () => {
  return (
    <header className="header-top">
      <div className="logo">
        <Link to="/">
          <img src="/images/MWLogo.png" alt="Malaysian Warriors Logo" />
        </Link>
      </div>
      <div>The Malaysian Warriors Football Club</div>
      <nav className="nav-links">
        <Link to="/gallery">Gallery</Link>
        <Link to="/members">Members</Link>
        <Link to="/contact">Contact</Link>
      </nav>
    </header>
  );
};

export default HeaderTop;
