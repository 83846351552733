// MainPage.js
import React from 'react';
import HeaderTop from '../components/headertop';
import SponsorsBar from './sponsorsbar';
import '../styles/mainpage.scss';

const MainPage = () => {
  const backgroundStyle = {
    backgroundImage: `url('/images/Background_Pic.png')`,
  };

  return (
    <>
      <HeaderTop />
      <SponsorsBar />
      <div className="main-content" style={backgroundStyle}>
        <div className="background-overlay" />
        <div className="content-wrapper">
          <h1>The Malaysian Warriors Football Club</h1>
          <p className="tagline">The way of the world in Malaysia</p>
          <div className="cta-buttons">
            <span className="primary-button">Do you want to join us?</span>
            <br />
            <a className="secondary-button" href="/contact">Join The Club</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
